import Layout from "@components/layout/Layout/Layout"
import ArtistProfilePage from "@partials/ArtistProfilePage/ArtistProfilePage"
import React from "react"

export default function Artist(props) {
  return (
    <Layout title={`Masterbrews | NFT Artist ${props.name}`}>
      <ArtistProfilePage {...props} />
    </Layout>
  )
}

